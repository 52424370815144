import { Accent, CustomButton, DetailItem } from '@/lib/components';
import { Heading } from '@/lib/components/core/typography/heading';
import { useRelationContext } from '@/lib/context';
import { IdentifierCategory } from '@/lib/types';
import { Divider, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const Company = () => {
  const { relation } = useRelationContext();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'company.section',
  });

  const handleNavigateToSupport = () => {
    navigate('/settings/support?tab=supportTickets');
  };

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size="small">
        <Heading type="h2" text={t('details.title')} />
        <DetailItem name={t('details.name')} value={relation?.name} />
        <DetailItem name={t('details.zipcode')} value={relation?.zipcode} />
        <DetailItem
          name={t('details.address')}
          value={`${relation?.street || ''} ${relation?.houseNumber || ''}`}
        />
        <DetailItem name={t('details.city')} value={relation?.city} />
        <DetailItem name={t('details.country')} value={relation?.country} />
        <DetailItem
          name={t('details.kvkNumber')}
          value={
            relation?.identifiers.find(
              (identifier) => identifier.category === IdentifierCategory.COC,
            )?.identifier
          }
        />
        <DetailItem
          name={t('details.alfaClientNumber')}
          value={
            relation?.identifiers.find(
              (identifier) =>
                identifier.category === IdentifierCategory.CUSTOMER,
            )?.identifier
          }
        />
        <Divider />

        <Heading type="h2" text={t('mailbox.title')} />

        <Accent color="secondary">{t('mailbox.prefix')}</Accent>
        <DetailItem name={t('mailbox.buyInvoice')} value={relation?.mailbox} />
        <DetailItem
          name={t('mailbox.sellInvoice')}
          value={
            relation?.mailbox?.split('@')[0] +
            '+verkoop@' +
            relation?.mailbox?.split('@')[1]
          }
        />
        <Divider />

        <Heading type="h2" text={t('senders.title')} />
        <Accent color="secondary">{t('senders.prefix')} </Accent>
        <ul>
          {relation?.safeEmailAddresses?.map((x) => (
            <li key={x}>{x}</li>
          ))}
        </ul>
        <Divider />

        <Accent color="secondary" type="p">
          Op deze pagina worden de ingestelde bedrijfsgegevens getoond, als deze
          gewijzigd moeten worden, geef dit dan door via het{' '}
          <CustomButton color="secondary" ghost size="small" onClick={handleNavigateToSupport}>
            support formulier
          </CustomButton>
        </Accent>
      </Space>
    </>
  );
};
